import { Path } from '.'

export function deleteIn(source: Record<string, any>, path: Path) {
  const _path = Array.isArray(path) ? path.join('.') : path
  const keys = _path.split('.')
  
  if (source && keys.length) {
    let iter = source
    for (let i = 0; i < keys.length; i++) {
      const currentKey = keys[i]
      if (i === keys.length - 1 && iter[currentKey]) {
        if (Array.isArray(iter)) {
          iter.splice(currentKey as unknown as number, 1)
        } else {
          delete iter[currentKey]
        }
        break
      }
      iter = iter[currentKey]
    }
  }

  return source
}
