import get from 'lodash/get'

import { Path } from '.'
/**
 * Deep getIn
 */
export function getIn<T>(obj: Record<string, any>, _path: Path, notFoundValue?: T): T {
  const path = Array.isArray(_path) ? _path.join(".") : _path
  const value =  get(obj,path)
  return value || notFoundValue
}

