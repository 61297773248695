import { gql, useQuery } from '@apollo/client'
import React from 'react'

export const PARTNER_QUERY = gql`
  query Partner($id: String!) {
    partner(id: $id) {
      id
      partnerId
      title
      imageUrl
    }
  }
`

const PartnerLogo = ({ partnerId }) => {
  const { data } = useQuery(PARTNER_QUERY, {
    skip:        !partnerId,
    fetchPolicy: 'cache-and-network',
    variables:   {
      id: partnerId,
    },
  })

  return <div><img className="item-editor-header-logo" src={data?.partner?.imageUrl}  /></div>
}

export default PartnerLogo
