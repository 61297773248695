import { deleteIn, getIn, hasIn, setIn } from '@cls/deep'
import { RootState } from '@cls/redux'
import { produce } from 'immer'
import { isEqual } from 'lodash'
import isPlainObject from 'lodash/isPlainObject'

import { templateIds } from '../../../config/constants'
import { getEventJourneyStatus, titleFields } from '../utils'
import { setEditingVersionId } from './editor-redux'
import { getLocalTagIds, getTagIds, objectIsFalse } from './lib/pre-save'

const dateSortMap = {
  day:   2,
  month: 1,
  year:  0,
}
export function selectEditingVersion(state: RootState) {
  const { activeVersion, workingVersions } = state.versions
  let itemId = activeVersion?._id

  if (!itemId && workingVersions[-1]) {
    // bad and dirty: we assume a draft version
    itemId = -1
  }
  const workingVersion = state.versions.workingVersions[itemId]
  const editingVersionId = state.editor.editingVersionId
  const version = workingVersion && !editingVersionId ? workingVersion : activeVersion
  return version
}

export function createNewItem(template: any, parentId?: any, parentTitle?: any) {
  return (dispatch: any) => {
    const data = {
      template,
      parentId,
      parentTitle,
    }

    if (template) {
      dispatch(newItemAction(data))
    }
  }
}

export function newItemAction(data: any) {
  return  (dispatch: any, getState: any) => {
    const {
      editor,
    } = getState()

    const templates = editor.templates
    const template = templates.find((t: any) => t.id === parseInt(data?.template.id))

    dispatch({
      type: 'ITEM_NEW',
      data: { ...data, template: template || data.template },
    })
  }
}

export function arrayItemMove(id: any, beforeIdx: any, afterIdx: any) {
  return (dispatch: any, getState: any) => {
    const itemId = setupUpdate(dispatch, getState)
    dispatch({
      type:    'ITEM_ARRAY_MOVE',
      payload: { id, beforeIdx, afterIdx, itemId },
    })
  }
}

export function arrayItemsAdd(key: any, items: any, sort: any) {
  return (dispatch: any, getState: any) => {
    const itemId = setupUpdate(dispatch, getState)
    dispatch({
      type:    'ITEM_ARRAY_ADD_BATCH',
      payload: { key, items, sort, itemId },
    })
  }
}

export function arrayItemAdd(id: any, options?: any) {
  return (dispatch: any, getState: any) => {
    const itemId = setupUpdate(dispatch, getState)
    dispatch({
      type:    'ITEM_ARRAY_ADD',
      payload: {
        itemId,
        id,
        options,
      },
    })
  }
}

export function arrayItemDelete(id: any, deleteIdx: any) {
  return (dispatch: any, getState: any) => {
    const itemId = setupUpdate(dispatch, getState)
    dispatch({
      type:    'ITEM_ARRAY_DELETE',
      payload: { id, deleteIdx, itemId },
    })
  }
}

export function notifyPublishing() {
  return {
    type: 'NOTIFY_PUBLISHING',
  }
}

export function publishSuccess() {
  return {
    type: 'PUBLISH_SUCCESS',
  }
}

export function publishFailed() {
  return {
    type: 'PUBLISH_FAILED',
  }
}

export function removeLocalVersion(id: any) {
  return { type: 'REMOVE_LOCAL_VERSION', id }
}

export function createActiveVersion(item: any) {
  return {
    type: 'CREATE_ACTIVE_VERSION',
    item: item,
  }
}

export function createLocalVersion(item: any) {
  return {
    type: 'CREATE_LOCAL_VERSION',
    item: item,
  }
}

export function arrayItemPrepare(template: any, dataPath: any, itemsData: any) {
  return (dispatch: any, getState: any) => {
    const itemId = setupUpdate(dispatch, getState)
    const action = {
      type: 'ITEM_ARRAY_PREPARE',
      data: { dataPath, itemsData, template, itemId },
    }

    if (template) {
      dispatch(action)
    }
    return action
  }
}

const checkForChanges = data => (dispatch: any, getState: any) => {
  const { versions } = getState()
  const { activeVersion } = versions

  if (!activeVersion) {
    return true
  }

  const itemId = activeVersion._id
  const currentWorkingVersion = getIn(versions, ['workingVersions', itemId])


  const { key, value } = data

  let oldData

  if (key) {
    oldData = getIn(currentWorkingVersion || activeVersion, key)
  } else {
    oldData = currentWorkingVersion || activeVersion
  }

  if (!oldData) {
    return true
  }

  if (Array.isArray(value) && Array.isArray(oldData)) {
    return !isEqual(oldData, value)
  }

  const neededKeys = Object.keys(value || data)
  const dataHasChanged = neededKeys.some(k => {
    const oldValue = oldData[k]
    const newValue = (value || data)[k]

    if (Array.isArray(oldValue) && Array.isArray(newValue)) {
      return !isEqual(oldValue, newValue)
    }

    if (newValue === false && oldValue === true) {
      return true
    }

    if (!newValue && newValue !== null && newValue !== '') {
      return false
    }

    if (newValue && typeof newValue === 'object') {
      if (!oldValue?.value && !newValue?.value) {
        const neededSubKeys = Object.keys(newValue)
        return  neededSubKeys?.length ? neededSubKeys.some(subK => {
          return !isEqual(oldValue?.[subK], newValue[subK])
        }) : false
      }

      if (oldValue?.isApproximateDate !== undefined || newValue.isApproximateDate !== undefined) {
        return !isEqual(oldValue?.isApproximateDate, newValue.isApproximateDate)
      }

      return !isEqual(oldValue?.value, newValue.value)
    }

    return !isEqual(oldData[k], newValue)
  })

  return dataHasChanged
}

export const setupUpdate = (dispatch: any, getState: any) => {
  const { versions } = getState()
  const { activeVersion } = versions
  const itemId = activeVersion._id
  const currentWorkingVersion = getIn(versions, ['workingVersions', itemId])
  if (!currentWorkingVersion) {
    dispatch(createLocalVersion(activeVersion))
    dispatch(setEditingVersionId(null))
  }
  return itemId
}

export function itemUpdate(data: any) {  
  return (dispatch: any, getState: any) => {
    const hasChanges = dispatch(checkForChanges(data))

    if (!hasChanges) {
      return
    }

    const {
      editor,
      versions: { activeVersion },
    } = getState()

    const templates = editor.templates
    const template = templates.find((t: any) => t.id === activeVersion._tplId)

    if (!template) {
      throw new Error('Cannot proceed without a template')
    }
    const itemId = setupUpdate(dispatch, getState)
    dispatch(
      updateItem({
        data,
        template,
        itemId,
      })
    )
  }
}

export function updateItem(payload: any) {
  return {
    type: 'ITEM_UPDATED',
    payload,
  }
}

const initialState = {
  workingVersions: {},
  activeVersion:   {},
  publishing:      false,
}

function fillWithDefaultValues(item, fields) {
  if (!fields || !fields?.length) {
    return item
  }
  fields.forEach((field) => {
    if (!item[field.id] && field.type === 'text') {
      item[field.id] = field.defaultValue
    }
  })
  return item
}

function createNewItemReducerHandler(draftState: any, template: any, parentId: any, parentTitle: any) {
  const newId = -1

  const newItem: any = {
    _id:         newId,
    _tplId:      parseInt(template.id),
    _created_at: new Date().toISOString(),
    _status:     'draft',
  }

  // check if the new content requires a parent and the template is not for persons
  if (template.parentId && newItem._tplId !== templateIds.author) {
    if (parentId) {
      newItem._parentId = parentId
    }

    if (parentTitle) {
      newItem._parentTitle = parentTitle
    }
  }

  if (template.start) {
    const date = Math.floor(new Date().getFullYear() - 20)
    newItem[template.start] = {
      value: `${date}_0_0`,
    }
    newItem._start = date
  }

  draftState.editingVersionId = null
  draftState.activeVersion = fillWithDefaultValues(newItem, template.fields)
  if (!template.parentId) {
    draftState.relatedList = []
  }
}

function itemArrayAddReducerHandler(draftState: any, id: any, itemId: any, options: any = {}) {
  const { placeControlsAtStart } = options

  const items = getIn(draftState, ['workingVersions', itemId, ...id])
  const emptyItem = {}
  if (!items) {
    // nothing here yet!
    setIn(draftState, ['workingVersions', itemId, ...id], [emptyItem])
    return
  }
  if (Array.isArray(items)) {
    if (!placeControlsAtStart) {
      const l = items.length
      const lastItem = items[l - 1]

      if (lastItem && Object.entries(lastItem).length === 0) {
        // last item still empty, don't do anything
        return
      } else {
        setIn(draftState, ['workingVersions', itemId, ...id, l], emptyItem)
      }
    } else {
      items.unshift(emptyItem)
      items.forEach((arrayItem: any, index: any) => arrayItem._index = index)
      setIn(
        draftState,
        ['workingVersions', itemId, ...id],
        items
      )
    }
    return
  }

  // convert to a list and add an empty item at the end
  setIn(draftState, ['workingVersions', itemId, ...id], [items, emptyItem])
}

function itemArrayDeleteReducerHandler(draftState: any, id: any, deleteIdx: any, itemId: any) {
  if (hasIn(draftState, ['workingVersions', itemId, id, deleteIdx])) {
    deleteIn(draftState, ['workingVersions', itemId, id, deleteIdx])
    if (id === 'journeys') {
      const journeys = getIn<Array<any>>(draftState, ['workingVersions', itemId, 'journeys'])
      const events = getIn<Array<any>>(draftState, ['workingVersions', itemId, 'chronology'])
      const newEvents = getEventJourneyStatus(events, journeys)
      setIn(draftState, ['workingVersions', itemId, 'chronology'], newEvents)
    }
  } else {
    deleteIn(draftState, ['workingVersions', itemId, id])
  }
}

function itemArrayAddBatchReducerHandler(draftState: any, key: any, items: any, sort: any, itemId: any) {
  const id = Array.isArray(key) ? key : key.split('.')
  const path = ['workingVersions', itemId, ...id]
  const _items = (getIn(draftState, path) as Array<any> ?? []).concat(items)
  if (sort) {
    _items.sort((a: any, b: any) => a[sort] - b[sort])
  }

  return setIn(draftState, path, _items)
}

function itemArrayMoveReducerHandler(draftState: any, id: any, beforeIdx: any, afterIdx: any, itemId: any) {
  const items: Array<any> = getIn(draftState, ['workingVersions', itemId, id])
  const [removed] = items.splice(beforeIdx, 1)
  items.splice(afterIdx, 0, removed)
  items.forEach((arrayItem: any, index: any) => arrayItem._index = index)

  setIn(draftState, ['workingVersions', itemId, id], items)
}

function itemArrayPrepareReducerHandler(draftState: any, dataPath: any, itemsData: any, template: any, itemId: any) {
  const field = template.fields.find((f: any) => f.id === dataPath)

  if (field.itemArray) {
    const existingItems: Array<any> = getIn(draftState, ['workingVersions', itemId, dataPath]) || []
    const newItems = [...existingItems, ...itemsData]
    setIn(draftState, ['workingVersions', itemId, dataPath], newItems)
  } else {
    setIn(draftState, ['workingVersions', itemId, dataPath], itemsData[0])
  }
}

function itemUpdatedReducerHandler(draftState: any, itemId: any, taggable: any, value: any, template: any, key: any, sort: any) {
  const id = Array.isArray(key) ? key : key.split('.')
  const path = ['workingVersions', itemId, ...id]

  if (typeof value === 'object') {
    if (objectIsFalse(value)) {
      console.info('Deleting value at', path)
      deleteIn(draftState, path)
      return
    }
  }

  const val = value
  setIn(draftState, ['workingVersions', itemId, '_lastUpdated'], new Date().toISOString())

  let localTagIdsPath
  if (isPlainObject(val)) {
    localTagIdsPath = [...path, 'tagIds']
    const existing: any = getIn(draftState, path)

    // We can check to see if this was an array field that is now a single value field and, if so,
    // we can overwrite the previous one.
    if (Array.isArray(existing)) {
      console.info('Overwriting existing array element at', path)
      setIn(draftState, path, val)
    } else {
      setIn(draftState, path, { ...existing, ...val })
    }
  } else {
    localTagIdsPath = [...path.slice(0, -1), 'tagIds']
    console.info('Setting', val, 'at', path)
    setIn(draftState, path, val)
  }

  const titleIds = titleFields(template)

  if (titleIds.includes(id[0])) {
    const title = titleIds
      .map((t: any) => {
        const f: any = getIn(draftState, ['workingVersions', itemId, t])
        return typeof f === 'object' ? f.value : f
      })
      .join('   ')
    setIn(draftState, ['workingVersions', itemId, '_title'], title)
  }

  if (!taggable) {
    const localTagIds = getLocalTagIds(value.value || value.body || val)
    if (Array.isArray(localTagIds)) {
      setIn(draftState, localTagIdsPath, localTagIds)
    }
  }
  let globalTagIds = getTagIds(getIn(draftState, ['workingVersions', itemId]))
  globalTagIds = Array.from(new Set(globalTagIds))
  setIn(draftState, ['workingVersions', itemId, '_tagIds'], globalTagIds)

  if (sort && isArrayField(template, id[0])) {
    const num = 0.000001
    const itemsPath = path.slice(0, -1)
    const items: Array<any> = getIn(draftState, itemsPath)
    if (items && items.length) {
      const sortedAndIndexed = produce(items, (draftItems: any) => {
        const sorted = draftItems.map((i: any, idx: any) => {
          return {
            ...i,
            _index: idx,
          }
        })

        sorted.sort((a: any, b: any) => {
          let aSortValue
          let bSortValue
          if (sort.key && sort.key === 'start') {

            if (sort.subKey === 'full') {
              const [aYear, aMonth, aDay] = a?.[sort.key]
                ?.split('_')
                .map(Number)
              const [bYear, bMonth, bDay] = b?.[sort.key]
                ?.split('_')
                .map(Number)

              if (aDay !== bDay) {
                return aDay - bDay
              } else if (aMonth !== bMonth) {
                return aMonth - bMonth
              } else {
                return aYear - bYear
              }
            } else {
              aSortValue = a?.[sort.key]?.split('_')?.[dateSortMap[sort.subKey]]
              bSortValue = b?.[sort.key]?.split('_')?.[dateSortMap[sort.subKey]]
            }

            if (!aSortValue || !bSortValue) {
              return
            }

          } else {
            aSortValue = a[sort]
            bSortValue = b[sort]
          }

          if (isFinite(aSortValue) && isFinite(bSortValue)) {
            const aIdx = a._index
            const bIdx = b._index
            return (
              (aSortValue + aIdx * num || 0) - (bSortValue + bIdx * num || 0) ||
                    (aIdx || 0) - (bIdx || 0)
            )
          } else {
            return (
              aSortValue.localeCompare(bSortValue)
            )
          }
        })

        return sorted.map((i: any, idx: any) => {
          return {
            ...i,
            _index: idx,
          }
        })
      })

      setIn(draftState, itemsPath, sortedAndIndexed)
    }
  }
  if (id[0] === 'journeys') {
    const journeys = getIn<Array<any>>(draftState, ['workingVersions', itemId, 'journeys'])
    const events = getIn<Array<any>>(draftState, ['workingVersions', itemId, 'chronology'])
    const newEvents = getEventJourneyStatus(events, journeys)
    setIn(draftState, ['workingVersions', itemId, 'chronology'], newEvents)
  }
}

function itemUpdatedMergeReducerHandler(draftState: any, itemId: any, taggable: any, value: any, data: any) {
  setIn(draftState, ['workingVersions', itemId, '_lastUpdated'], new Date().toISOString())

  Object.keys(data).forEach(k => {
    const localTagIdsPath = ['workingVersions', itemId, k, 'tagIds']
    const val = data[k]
    if (typeof val === 'object') {
      const existing: any = getIn(draftState, ['workingVersions', itemId, k])
      setIn(draftState, ['workingVersions', itemId, k], {
        ...existing,
        ...val,
      })
    } else {
      setIn(draftState, ['workingVersions', itemId, k], val)
    }
    if (!taggable && val) {
      const localTagIds = getLocalTagIds(val.value || val.body || val)
      if (Array.isArray(localTagIds) && localTagIds.length > 0) {
        setIn(draftState, localTagIdsPath, localTagIds)
      }
    }
  })

  let globalTagIds = getTagIds(getIn(draftState, ['workingVersions', itemId]))
  globalTagIds = Array.from(new Set(globalTagIds))
  setIn(draftState, ['workingVersions', itemId, '_tagIds'], globalTagIds)
}


function isArrayField(template: any, id: any) {
  const fieldTemplate = template.fields.find((field: any) => field.id === id)

  return fieldTemplate && fieldTemplate.itemArray === true
}

export function reducer() {
  return produce((draftState, action = {}) => {
    switch (action.type) {

      case 'ITEM_NEW':
        const { parentId, parentTitle } = action.data
        createNewItemReducerHandler(draftState, action.data.template, parentId, parentTitle)
        break

      case 'REMOVE_LOCAL_VERSION':
        delete draftState.workingVersions[action.id]
        break

      case 'ITEM_ARRAY_ADD':
        itemArrayAddReducerHandler(draftState, action.payload.id.split('.'), action.payload.itemId, action.payload.options)
        break

      case 'ITEM_ARRAY_DELETE':
        const { deleteIdx } = action.payload
        itemArrayDeleteReducerHandler(draftState, action.payload.id, deleteIdx, action.payload.itemId)
        break

      case 'ITEM_ARRAY_ADD_BATCH':
        const { items, sort: addBatchSort } = action.payload
        itemArrayAddBatchReducerHandler(draftState, action.payload.key, items, addBatchSort, action.payload.itemId)
        break

      case 'ITEM_ARRAY_MOVE':
        const { beforeIdx, afterIdx } = action.payload
        itemArrayMoveReducerHandler(draftState, action.payload.id, beforeIdx, afterIdx, action.payload.itemId)
        break

      case 'ITEM_ARRAY_PREPARE':
        const { dataPath, itemsData } = action.data
        itemArrayPrepareReducerHandler(draftState, dataPath, itemsData, action.data.template, action.data.itemId)
        break

      case 'CREATE_LOCAL_VERSION':
        setIn(draftState, ['workingVersions', action.item['_id']], action.item)
        break

      case 'CREATE_ACTIVE_VERSION':
        draftState.activeVersion = action.item
        break

      case 'NOTIFY_PUBLISHING':
        draftState.publishing = true
        break

      case 'PUBLISH_SUCCESS':
      case 'PUBLISH_FAILED':
        draftState.publishing = false
        break

      case 'ITEM_UPDATED':
        if (!action.payload?.data) {
          return draftState
        }
        const { data, itemId, template } = action.payload
        const { key, value, sort = false, taggable = false } = data

        if (key && typeof value !== 'undefined') {
          itemUpdatedReducerHandler(draftState, itemId, taggable, value, template, key, sort)
        } else {
          itemUpdatedMergeReducerHandler(draftState, itemId, taggable, value, data)
        }
        break

    }
  }, initialState)
}
