import React, { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { showLinkEditor as showLinkEditorAction } from './state/actions'

const connector = connect(undefined, {
  showLinkEditor: showLinkEditorAction,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface OwnProps {
  active: boolean
  icon?: string
  itemId: number
  itemTitle?: string
  query: string
  templateId?: number
  updateHandler: (updates: any) => void
}

class ItemSelector extends PureComponent<PropsFromRedux & OwnProps> {
  static defaultProps = {
    active: false,
    icon: 'link',
  }

  componentDidMount() {
    if (this.props.active) {
      this.selectHandler()
    }
  }

  selectHandler = () => {
    const {
      itemId,
      templateId,
      showLinkEditor,
      query,
      updateHandler,
    } = this.props

    showLinkEditor({
      query: query || '',
      itemId: itemId ?? 0,
      templateId: templateId ?? 0,
      updateHandler: updateHandler,
      clearHandler: this.clearHandler,
    })
  }

  clearHandler = () => {
    this.props.updateHandler({})
  }

  render() {
    const { itemTitle, icon } = this.props

    return (
      <div className="item-selector" onClick={this.selectHandler}>
        <i className="icon md-16">{icon}</i>
        <span className="item-selector-title">{itemTitle}</span>
      </div>
    )
  }
}

export default connector(ItemSelector)
